import './assets/css/styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './assets/css/custom.css'
import 'font-awesome/css/font-awesome.css'
import { RouterProvider } from 'react-router-dom'
import ProjectRouter from './components/router/ProjectRouter'
import PublicRouter from './components/router/PublicRouter'

function App() {
  return (
  <>
  {localStorage.token != undefined?
      <RouterProvider router={ProjectRouter}/>:
      <RouterProvider router={PublicRouter}/>
  }
    
  </>
);
}

export default App;
