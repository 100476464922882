import {Link, Outlet} from 'react-router-dom';
import axios from 'axios';
import Constants from '../../Constants';
import Swal from 'sweetalert2';

const Master = () => {

  const handleLogout = () =>{
      Swal.fire({
          title: "Are you sure?",
          text: "You will be logout!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Logout!"
          }).then((result) => {
          if (result.isConfirmed) {
              axios.post(`${Constants.BASE_URL}/logout`,{'id':Constants.Auth.id}).then(res=>{
                localStorage.removeItem('auth')
                localStorage.removeItem('token')
                window.location.href='/';
              }).catch(err =>{
                alert(err);
              })
          }
      });
    }

    return (
    <>
      <nav className="sb-topnav navbar navbar-expand">
        <i className="fa fa-home" style={{ fontSize:'20px',color:'#fff' }}></i>
        <div className="text-light"><b>School Management System</b></div>
          <i onClick={handleLogout} className="fa fa-sign-out" style={{ fontSize:'20px',color:'#fff' }}></i>
        </nav>
        <Outlet />
    </>
    );
}

export default Master;