import { createBrowserRouter } from "react-router-dom";
import Master from "../layout/Master";
import Dashboard from "../modules/Dashboard";
import Error500 from "../modules/Error500";
import Ledger from "../modules/menu/Ledger";
import Homework from "../modules/menu/Homework";
import Diary from "../modules/menu/Diary";
import Attendance from "../modules/menu/Attendance";
import TimeTable from "../modules/menu/TimeTable";
import Notice from "../modules/menu/Notice";
import Password from "../modules/menu/Password";

const ProjectRouter =createBrowserRouter([
    {
        path:'/',
        element:<Master/>,
        children:[
            {
                path:'/',
                element:<Dashboard/>
            },
            {
                path:'/ledger',
                element:<Ledger/>
            },
            {
                path:'/homework',
                element:<Homework/>
            },
            {
                path:'/diary',
                element:<Diary/>
            },
            {
                path:'/attendance',
                element:<Attendance/>
            },
            {
                path:'/time_table',
                element:<TimeTable/>
            },
            {
                path:'/notice',
                element:<Notice/>
            },
            {
                path:'/password',
                element:<Password/>
            },
            {
                path:'/error-500',
                element:<Error500/>
            }
        ]
    }
])

export default ProjectRouter;
