import { useState } from 'react';
import axios from 'axios';
import Constants from '../../Constants';
import './../../assets/css/login.css';
import signin_img from './../../assets/img/sign_in.png'
import ButtonLoader from '../partials/buttonLoader';

const Login = () => {
    const [input,setInput] = useState({});
    const [err,setErr] = useState([]);
    const [loader,setLoader] = useState(false);
    const [isPasswordVisible,setPasswordVisible] = useState(false);

    const handleInput = (e) => setInput(prevState => ({...prevState, [e.target.name]: e.target.value}));

    const handleLogin = () => {
        setLoader(true)
        axios.post(`${Constants.BASE_URL}/auth`, input).then(res=>{
            if(res.data.status==422){
                setLoader(false);
                setErr(res.data.error);
            }else{
                localStorage.auth = res.data.auth;
                localStorage.token = res.data.token;
                window.location.pathname='/';
            }
        }).catch(errors =>{
            setLoader(false);
            if(errors.response.status == 422){
                setErr(errors.response.data.errors);
            }
        })
    }
    return (
    <>
        <div className="login-form">
            <div className="row">
                <div className="col-md-12">
                    <div className="form-header">
                        <img src={signin_img}/>
                        <h4>Welcom Back!</h4>
                        <p>Login to your existing account of School App.</p>
                    </div>
                    <div className="from-box mt-4">
                        <label className="w-100">
                            <input 
                                type="text" 
                                name='username' 
                                className={err.username != undefined ? "form-control is-invalid": "form-control"} 
                                value={input.username} 
                                onChange={handleInput} 
                                placeholder="Enter Your Userid"
                            />
                            <i className='fa fa-user input-icon'></i>
                            <span className='err-msg'>{err.username != undefined ? err.username[0] : null}</span>
                        </label>
                        <label className="w-100">
                            <input 
                                type={isPasswordVisible?'text':'password'} 
                                name='password' security={!isPasswordVisible} 
                                className={err.password != undefined ? "form-control is-invalid": "form-control"} 
                                value={input.password} 
                                onChange={handleInput} 
                                placeholder="Enter Your Password"
                            />
                            <i className='fa fa-lock input-icon'></i>
                            <span className='err-msg'>{err.password != undefined ? err.password[0] : null}</span>
                            <a href='javascript:void(0)' onClick={()=>setPasswordVisible(!isPasswordVisible)}>
                                {isPasswordVisible? (
                                    <i className='fa fa-eye-slash show-icon'></i>
                                ):(
                                    <i className='fa fa-eye show-icon'></i>
                                )}
                            </a>
                            {/* <a href='javascript:void(0)' style={{ float:'right' }}>Forgot Password?</a> */}
                        </label>
                        <div className="mt-4">
                            {!loader? <button type="submit" className="btn btn-primary w-100" onClick={handleLogin}>Login</button>: <ButtonLoader/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default Login;