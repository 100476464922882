import axios from "axios";
import Constants from "../../../Constants";
import { useState } from "react";
import ButtonLoader from "../../partials/buttonLoader";
import Swal from "sweetalert2";

const Homework = () => {
    const [loader,setLoader]=useState(false);
    const [msg,setMsg]=useState('');
    const [oldPassword,setOldPassword]=useState('');
    const [newPassword,setNewPassword]=useState('');
    const [confPassword,setConfPassword]=useState('');
    
    const changePassword = () => {
        setMsg('');
        if(oldPassword=='' || newPassword=='' || confPassword==''){
            setMsg('Some feilds are empty!')
        }else if(Constants.Auth.pass!=oldPassword){
            setMsg('Wrong old password, try again..')
        }else if(newPassword!=confPassword){
            setMsg('New & Confirm Passwprd not matched!')
        }else{
            setLoader(true)
            axios.post(`${Constants.BASE_URL}/password`, {'id':Constants.Auth.id,'password':newPassword}).then(res=>{
                localStorage.auth = res.data;
                setLoader(false);
                Swal.fire({
                    title: "Success!",
                    text: "Your password has been changed!",
                    icon: "success",
                    timer: 1500
                });
                window.location.href='/';
            }).catch(errors =>{
                setMsg(errors);
                setLoader(false);
            })
        }
    }

    return (
        <div className="container">
            <span className="main-heading">Change Password</span>
            <div class="bg-white p-3 mt-3">
                <div className="">
                    <label>Old Password</label><br/>
                    <input type="password" className="form-control" placeholder="Enter Your Old Password" onChange={(e)=>setOldPassword(e.target.value)}/>
                </div>
                <div className="mt-2">
                    <label>New Password</label><br/>
                    <input type="text" className="form-control" placeholder="Enter Your New Password" onChange={(e)=>setNewPassword(e.target.value)}/>
                </div>
                <div className="mt-2">
                    <label>Confirm Password</label><br/>
                    <input type="password" className="form-control" placeholder="Confirm Your New Password" onChange={(e)=>setConfPassword(e.target.value)}/>
                </div>
                <div className="mt-2 text-danger text-center">
                    <p>{msg}</p>
                </div>
                <div className="mt-3">
                    {!loader? <button type="submit" className="btn btn-primary w-100" onClick={changePassword}>Save Changes</button>: <ButtonLoader/>}
                </div>
            </div>
        </div>
    );
};

export default Homework;