import { Link } from "react-router-dom";
import signin_img from './../../assets/img/sign_in.png'


const FirstPage = () => {
    const redirectUrl = () => {
        window.location.href = 'https://school.softinnovation.in'
    }
    const myStyle = {
        height: '50px',
        paddingTop: '5%',
        fontSize: '20px',
        textAlign: 'center',
        background: '#3b72d6',
        color: '#fff',
        fontWeight: 'bold',
        
        // boxShadow: "0 4px 13px 1px black",
      };
    return (
    <>
        <div className="container">
            <div className="row">
                <img src={signin_img}/>
                <div className="col-12" style={{ marginTop:"30px", marginBottom:"50px",textAlign:"center",fontFamily:"serif" }}>
                    <h3>Welcome to <b><i>BrightPath</i></b></h3>
                    <p style={{ fontSize:"14px" }}>Empowering Education, Anytime, Anywhere.<br/>Stay Connected, Stay Informed.<br/>Your School, Your App.</p>
                </div>
                <div className="col-6">
                    <Link to="/">
                        <div className="card" style={myStyle} onClick={()=>redirectUrl()}>
                            <i>School Login</i>
                        </div>
                    </Link>
                </div>
                <div className="col-6">
                    <Link to="/login">
                        <div className="card" style={myStyle}>
                            <i>Student Login</i>
                        </div>
                    </Link>
                </div>
                <div className="col-12" style={{ position:"absolute",bottom:"10px",textAlign:"center",fontFamily:"serif" }}>
                    <p style={{ fontSize:"14px" }}>Designed & Developed by <b><i>Soft Innovation</i></b></p>
                    <p style={{ fontSize:"14px" }}>Website: <a href="https://softinnovation.in/"><b><i>www.softinnovation.in</i></b></a></p>
                </div>
            </div>
        </div>
    </>
    );
}

export default FirstPage;