import axios from "axios";
import Constants from './../../Constants';
import MenuIcon from '../partials/menuIcon';
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

const Dashboard = () => {
    const [auth,setAuth]=useState(Constants.Auth);
    const [recp,setRecp]=useState(0);

    const getData = () => {
        axios.post(`${Constants.BASE_URL}/getData`, {'id':Constants.Auth.id}).then(res=>{
            localStorage.auth = res.data.data;
            setAuth(JSON.parse(localStorage.auth))
            setRecp(res.data.recp)
        }).catch(errors =>{
            alert(errors);
        })
    }

    const handleLogout = () =>{
        Swal.fire({
            title: "Are you sure?",
            text: "You will be logout!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Logout!"
            }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`${Constants.BASE_URL}/logout`,{'id':Constants.Auth.id}).then(res=>{
                  localStorage.removeItem('auth')
                  localStorage.removeItem('token')
                  window.location.href='/';
                }).catch(err =>{
                  alert(err);
                })
            }
        });
    }

    useEffect(()=>{
        getData()
    },[])

    return (
    <div className="dashboard-box container">
        <span className="main-heading">Student Dashboard</span>
        <div className="row p-2">
            <div className="col-8">
                <h6>Welcome,</h6>
                <h5 style={{ fontWeight:'bold' }}>{auth.name}</h5> 
            </div>
            <div className="col-4 text-end">
                <img src={Constants.IMG_URL+auth.photo} width={45} style={{ 'borderRadius':'50%','border':'2px solid #000' }}/>
            </div>
        </div>
        <div className="row pt-2">
            <div className="col-6">
                <div className="card" style={{ backgroundColor:"#d85a5a" }}>
                    <div className="card-body">
                        <p>Due Amount</p>
                        <h2>{Math.round(auth.balance)}</h2>
                        <i className='fa fa-inr'></i>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="card" style={{ backgroundColor:"#5abd5a" }}>
                    <div className="card-body">
                        <p>Paid Amount</p>
                        <h2>{Math.round(recp)}</h2>
                        <i className='fa fa-sitemap'></i>
                    </div>
                </div>
            </div>
        </div>
        <div className="row p-2 pt-4">
            <div className="col-8">
                <h5><b>Main Menu</b></h5>
            </div>
            <div className="col-4 text-end h4">
                <i className='fa fa-th'></i>
            </div>
        </div>
        <div className="row p-2 pt-0">
            <MenuIcon link="/ledger" icon="fa-bar-chart" name="Ledger"/>
            <MenuIcon link="/homework" icon="fa-bookmark" name="Homework"/>
            <MenuIcon link="/diary" icon="fa-book" name="Diary Book"/>
            <MenuIcon link="/attendance" icon="fa-hand-paper-o" name="Attendance"/>
            <MenuIcon link="/time_table" icon="fa-calendar" name="Time Table"/>
            <MenuIcon link="/notice" icon="fa-bell" name="Notice"/>
            <MenuIcon link="/password" icon="fa-key" name="Password"/>
            <div className="col-3">
                <div className="menu-box">
                    <div className="icon">
                        <i onClick={handleLogout} className={'fa fa-power-off'}></i>
                    </div>
                    <p>Logout</p>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Dashboard;