import axios from "axios";
import Constants from "../../../Constants";
import { useEffect, useState } from "react";
import Loader from "../../partials/Loader";
import moment from "moment";

const Attendance = () => {
    const months = [
        { key: 4, name: 'April' }, { key: 5, name: 'May' }, { key: 6, name: 'June' },
        { key: 7, name: 'July' }, { key: 8, name: 'August' }, { key: 9, name: 'September' },
        { key: 10, name: 'October' }, { key: 11, name: 'November' }, { key: 12, name: 'December' },
        { key: 1, name: 'January' }, { key: 2, name: 'February' }, { key: 3, name: 'March' }
    ];

    const [load,setLoader]=useState(false);
    const [attendances,setAttendances]=useState([]);
    const [month,setMonth]=useState(new Date().getMonth()+1);
        
    const getAttendance = () => {
        setLoader(true);
        axios.post(`${Constants.BASE_URL}/attendance`, {'id':Constants.Auth.id,'session_id':Constants.Auth.session_id,'month':month}).then(res=>{
            setAttendances(res.data);
            setLoader(false);
        }).catch(errors =>{
            alert(errors);
            setLoader(false);
        })
    }

    const handleChange = (e) => {
        setMonth(parseInt(e.target.value, 10));
        getAttendance();
    };

    useEffect(()=>{
        getAttendance()
    },[month])

    return (
        <>
        {load? <Loader/> :
        <div className="container">
            <span className="main-heading">Attendance Report</span>
            <div style={{textAlign:'right'}}>
                <label>Month: </label> 
                <select name="month" onChange={handleChange}>
                    {months.map((mon)=>(
                        <option value={mon.key} selected={month === mon.key}>{mon.name}</option>
                    ))}
                </select>
            </div>
            <table className="table mt-2">
                <thead>
                    <tr>
                        <th>Sr No</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Remark</th>
                    </tr>
                </thead>
                {Object.keys(attendances).length>0 ?
                <tbody>
                    {attendances.map((attendance,i)=>(
                        <>
                        <tr key={i}>
                            <td>{++i}</td> 
                            <td>{moment(attendance.date).format('DD-MM-YYYY')}</td>
                            <td>{attendance.status}</td>
                            <td>{attendance.remark}</td>
                        </tr>
                        </>
                    ))}
                </tbody>
                : <tr><td colSpan={4} className="text-center">Data Not Found!</td></tr> }
            </table>
        </div>
        }
        </>
    );
};

export default Attendance;