import { createBrowserRouter } from "react-router-dom";
import FirstPage from "../modules/FirstPage";
import Login from "../modules/Login";
import AuthLayout from "../layout/AuthLayout";

const PublicRouter =createBrowserRouter([
    {
        path:'/',
        element:<AuthLayout/>,
        children:[
            {
                path:'/',
                element:<FirstPage/>
            },
            {
                path:'/login',
                element:<Login/>
            }
        ]
    }
])

export default PublicRouter;
