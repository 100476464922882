import axios from "axios";
import Constants from "../../../Constants";
import { useEffect, useState } from "react";
import Loader from "../../partials/Loader";

const TimeTable = () => {
    const days = [
        { key: 1, name: 'Monday' },{ key: 2, name: 'Tuesday' },{ key: 3, name: 'Wednesday' },
        { key: 4, name: 'Thursday' },{ key: 5, name: 'Friday' },{ key: 6, name: 'Saturday' }
    ];
    var x=new Date().getDay();
    const [load,setLoader]=useState(false);
    const [time_tables,setTimeTables]=useState([]);
    const [day,setDay]=useState(x==0?x+1:x);
    
    const getTimeTable = () => {
        setLoader(true);
        axios.post(`${Constants.BASE_URL}/time_table`, {'class':Constants.Auth.class,'section':Constants.Auth.section,'day':day}).then(res=>{
            setTimeTables(res.data);
            // alert(day)
            setLoader(false);
        }).catch(errors =>{
            alert(errors);
            setLoader(false);
        })
    }

    const handleChange = (e) => {
        setDay(parseInt(e.target.value, 10));
        getTimeTable();
    };

    useEffect(()=>{
        getTimeTable()
    },[day])

    return (
        <>
        {load? <Loader/> :
        <div className="container">
            <span className="main-heading">Your Time Table</span>
            <div style={{textAlign:'right'}}>
                <label>Day: </label> 
                <select name="month" onChange={handleChange}>
                    {days.map((d)=>(
                        <option value={d.key} selected={day === d.key}>{d.name}</option>
                    ))}
                </select>
            </div>
            <table className="table mt-3">
                <thead>
                    <tr>
                        <th>Period</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Subject</th>
                        <th>Teacher</th>
                        <th>Room</th>
                    </tr>
                </thead>
                {Object.keys(time_tables).length>0 ?
                <tbody>
                    {time_tables.map((time_table,i)=>(
                        <>
                        {time_table.period.slot==2?
                            <tr key={i}><td colspan={6}>THIS IS LUNCH TIMING</td></tr>
                        :
                            <tr key={i}>
                                <td>{time_table.period.name}</td>
                                <td>{time_table.period.start}</td>
                                <td>{time_table.period.end}</td>
                                <td>{time_table.subject?time_table.subject.name:'--'}</td>
                                <td>{time_table.teacher?time_table.teacher.name:'--'}</td>
                                <td>{time_table.room_no}</td>
                            </tr>
                        }
                        </>
                    ))}
                </tbody>
                : <tr><td colSpan={6} className="text-center">Data Not Found!</td></tr> }
            </table>
        </div>
        }
        </>
    );
};

export default TimeTable;