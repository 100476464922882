import axios from "axios";
import Constants from "../../../Constants";
import { useEffect, useState } from "react";
import Loader from "../../partials/Loader";

const Ledger = () => {
    let bal=0;
    const [load,setLoader]=useState(false);
    const [ledgers,setLedgers]=useState([]);
    
    const getLedger = () => {
        setLoader(true);
        axios.post(`${Constants.BASE_URL}/ledger`, {'id':Constants.Auth.id}).then(res=>{
            setLedgers(res.data);
            setLoader(false);
        }).catch(errors =>{
            alert(errors);
            setLoader(false);
        })
    }

    useEffect(()=>{
        getLedger()
    },[])

    return (
        <>
        {load? <Loader/> :
        <div className="container">
            <span className="main-heading">Ledger Report</span>
            <table className="table mt-3">
                <thead>
                    <tr>
                        <th>Particular</th>
                        <th>Date</th>
                        <th>Ref. No</th>
                        <th>Amount</th>
                        <th>Balance</th>
                    </tr>
                </thead>
                {Object.keys(ledgers).length>0 ?
                <tbody>
                    <tr>
                        <th>Op. Bal.</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <th>{Constants.Auth.op_bal}</th>
                    </tr>
                    {ledgers.map((ledger,i)=>(
                        <>
                        <tr key={i}>
                            <td>{ledger.particular}</td>
                            <td>{ledger.date}</td>
                            <td>{ledger.type==1?Constants.Auth.school.Prefix+ledger.ref_no:'--'}</td>
                            <td className={ledger.type==1?"text-success":"text-danger"}>
                            {Math.round(ledger.amount)} {ledger.type==1 ? "Cr" : "Dr"}
                            </td>
                            <td>{ledger.type==1?bal-=parseInt(ledger.amount):bal+=parseInt(ledger.amount)}</td>
                        </tr>
                        {ledger.type==1 && ledger.discount>0 ?
                            <tr key={++i}>
                                <td>Discount</td>
                                <td>{ledger.date}</td>
                                <td>--</td>
                                <td className="text-success">{Math.round(ledger.discount)} Cr</td>
                                <td>{bal-=parseInt(ledger.discount)}</td>
                            </tr>: null
                        }
                        {ledger.type==1 && ledger.penalty>0 ?
                            <tr key={++i}>
                                <td>Penalty</td>
                                <td>{ledger.date}</td>
                                <td>--</td>
                                <td className="text-danger">{Math.round(ledger.penalty)} Dr</td>
                                <td>{bal+=parseInt(ledger.penalty)}</td>
                            </tr>: null
                        }
                        </>
                    ))}
                    <tr>
                        <th>Total Txn.</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <th className={bal>0?"text-danger":"text-success"}>{bal}</th>
                    </tr>
                </tbody>
                : <tr><td colSpan={5} className="text-center">Data Not Found!</td></tr> }
            </table>
        </div>
        }
        </>
    );
};

export default Ledger;