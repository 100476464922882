
const Error500 = () => {
    return (
    <>
        <div className="row justify-content-center ">
            <div className="col-md-6">
                <h1 className="text-danger">Sorry, Page Not Found!</h1>
            </div>
        </div>            
    </>
    );
}

export default Error500;