import React from 'react';
import { Link } from 'react-router-dom';

const MenuIcon = (props) => {
    return (
        <Link to={props.link} className="col-3">
            <div className="menu-box">
                <div className="icon">
                    <i className={'fa '+props.icon}></i>
                </div>
                <p>{props.name}</p>
            </div>
        </Link>
    );
};

export default MenuIcon;