import axios from "axios";
import Constants from "../../../Constants";
import { useEffect, useState } from "react";
import Loader from "../../partials/Loader";
import * as moment from 'moment'

const Diary = () => {
    const [load,setLoader]=useState(false);
    const [diaries,setDiaries]=useState([]);
    const [subjects,setSubjects]=useState([]);
    
    const getSubject = () => {
        axios.post(`${Constants.BASE_URL}/subjects`, {'school_id':Constants.Auth.school_id}).then(res=>{
            setSubjects(res.data);
        }).catch(errors =>{
            alert(errors);
        })
    }
    
    const getDiary = () => {
        setLoader(true);
        axios.post(`${Constants.BASE_URL}/diary`, {'class':Constants.Auth.class,'section':Constants.Auth.section}).then(res=>{
            setDiaries(res.data);
            setLoader(false);
        }).catch(errors =>{
            alert(errors);
            setLoader(false);
        })
    }

    useEffect(()=>{
        getDiary()
        getSubject()
    },[])

    return (
        <>
        {load? <Loader/> :
        <div className="container">
            <span className="main-heading">Assigned Diaries</span>
            <table className="table mt-3">
                <thead>
                    <tr>
                        <th>Sr No</th>
                        <th>Diary Title</th>
                        <th>Diary Date</th>
                        <th>Subject & Work</th>
                    </tr>
                </thead>
                {Object.keys(diaries).length>0 ?
                <tbody>
                    {diaries.map((diary,i)=>(
                        <>
                        <tr key={i}>
                            <td>{++i}</td>
                            <td>{diary.title}</td>
                            <td width="20%">{moment(diary.date).format('DD-MM-YYYY')}</td>
                            <td width="60%">
                                <table width="100%">
                                {JSON.parse(diary.subjects).map((sub,i)=>(
                                    <tr>
                                        {subjects.map((subject)=>(
                                            subject.id==sub?<td>{subject.name}</td>:''
                                        ))}
                                        <td>{JSON.parse(diary.remark)[i]}</td>
                                    </tr>
                                ))}
                                </table>
                            </td>
                        </tr>
                        </>
                    ))}
                </tbody>
                : <tr><td colSpan={4} className="text-center">Data Not Found!</td></tr> }
            </table>
        </div>
        }
        </>
    );
};

export default Diary;