import axios from "axios";
import Constants from "../../../Constants";
import { useEffect, useState } from "react";
import Loader from "../../partials/Loader";
import moment from "moment";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

const Notice = () => {
    const [load,setLoader]=useState(false);
    const [notices,setNotices]=useState([]);

    const [show, setShow] = useState(false);
    const [img, setImg] = useState('');
    const [doc, setDoc] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const getNotice = () => {
        setLoader(true);
        axios.post(`${Constants.BASE_URL}/notice`, {'school_id':Constants.Auth.school_id,'session_id':Constants.Auth.session_id}).then(res=>{
            setNotices(res.data);
            setLoader(false);
        }).catch(errors =>{
            alert(errors);
            setLoader(false);
        })
    }
    
    const handleImage = (link) => {
        if(link.split('.').pop()=='jpg' || link.split('.').pop()=='jpeg' || link.split('.').pop()=='png'){
            handleShow()
            setImg(link)
            setDoc('')
        }else{
            setDoc(link)
        }
    }

    useEffect(()=>{
        getNotice()
    },[])

    return (
        <>
        {load? <Loader/> :
        <div className="container">
            <span className="main-heading">Notice Board</span>
            <table className="table mt-3">
                <thead>
                    <tr>
                        <th>Sr No</th>
                        <th>Notice Title</th>
                        <th width="17%">Date</th>
                        <th>Description</th>
                        <th>Attach.</th>
                    </tr>
                </thead>
                {Object.keys(notices).length>0 ?
                <tbody>
                    {notices.map((notice,i)=>(
                        <>
                        <tr key={i}>
                            <td>{++i}</td>
                            <td>{notice.title}</td>
                            <td>{moment(notice.issue_on).format('DD-MM-YYYY')}</td>
                            <td>{notice.content}</td>
                            <td>{notice.doc!=''?<Link to={doc} onClick={()=>handleImage(Constants.IMG_URL+notice.doc)}>View</Link>:'--'}</td>
                        </tr>
                        </>
                    ))}
                </tbody>
                : <tr><td colSpan={5} className="text-center">Data Not Found!</td></tr> }
            </table>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Notice Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={img} width="100%"/>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleClose}>Close</button>
                </Modal.Footer>
            </Modal>
        </div>
        }
        </>
    );
};

export default Notice;