import React from 'react';
import img from './../../assets/img/loader.gif';

const Loader = () => {
    return (
        <div style={{ backgroundColor:"#fff",height:'100vh', marginTop:-50,textAlign:"center", }}>
            <img src={img} style={{ marginTop:230 }}/>
        </div>
    );
};

export default Loader;